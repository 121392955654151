// Cookie Consent
(function () {
  let cookieConsentContent = '<div class="cookie-consent-modal">' +
    '<div id="js-modal-step-1" class="cookie-consent-step1">' +
    '<p class="h3">Datenschutz-Hinweis</p>' +
    '<p>Wir verwenden Cookies auf unserer Website.<br>Einige Cookies sind essenziell, andere helfen uns, die Website fortlaufend zu verbessern.</p>' +
    '<div id="js-cookie-accept" class="button button-primary">Alle akzeptieren und fortfahren</div>' +
    '<div id="js-cookie-optout" class="button button-primary">Ablehnen</div>' +
    '<div class="cookie-consent-footer">' +
    '<ul><li class="first"><div id="js-cookie-preferences" class="link toggle-preferences text-gray-600 hover:text-gray-800">Einstellungen</div></li>' +
    '<li><a href="https://www.christoph-baur.de/datenschutz">Datenschutz</a></li>' +
    '<li><a href="https://www.christoph-baur.de/impressum">Impressum</a></li></ul>' +
    '</div>' +
    '</div>' +
    '<div id="js-modal-step-2" class="cookie-consent-step2 cookie-consent-step2--hidden">' +
    '<div class="flex">' +
    '<div class="flex-auto"><p class="h4"><span>Essenziell</span></p></div>' +
    '<div class="flex-auto"><input class="toggle-switch toggle-switch--active" type="checkbox" disabled><span class="toggle-description text-gray-600">Erforderlich</span></div>' +
    '</div>' +
    '<p>Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für den einwandfreien Betrieb der Website erforderlich.</p>' +
    '<p class="h5">Sicherheit</p>' +
    '<p>Cookie des Website-Betreibers, um Cross-Site-Request-Forgery (CSRF) Angriffe zu verhindern.</p>' +
    '<dl>' +
    '<dt>Anbieter</dt><dd>Betreiber dieser Website</dd>' +
    '<dt>Cookie Name</dt><dd>XSRF-TOKEN</dd>' +
    '<dt>Cookie Laufzeit</dt><dd>1 Stunde</dd>' +
    '</dl>' +
    '<p class="h5">Session</p>' +
    '<p>Cookie des Website-Betreibers, um die Browser-Session zu identifizieren.</p>' +
    '<dl>' +
    '<dt>Anbieter</dt><dd>Betreiber dieser Website</dd>' +
    '<dt>Cookie Name</dt><dd>cb_session</dd>' +
    '<dt>Cookie Laufzeit</dt><dd>1 Stunde</dd>' +
    '</dl>' +
    '<p class="h5">Einstellungen</p>' +
    '<p>Cookie des Website-Betreibers, um die Cookie-Einstellungen des Besuchers zu speichern.</p>' +
    '<dl>' +
    '<dt>Anbieter</dt><dd>Betreiber dieser Website</dd>' +
    '<dt>Cookie Name</dt><dd>cb_settings, cb_modal</dd>' +
    '<dt>Cookie Laufzeit</dt><dd>1 Monat</dd>' +
    '</dl>' +

    '<div class="flex">' +
    '<div class="flex-auto"><p class="h4"><span>Statistiken</span></p></div>' +
    '<div class="flex-auto"><input id="js-tracking-enabled" class="toggle-switch" type="checkbox"><span class="toggle-description text-gray-600">Optional</span></div>' +
    '</div>' +
    '<p>Diese Cookies helfen uns zu verstehen, wie unsere Besucher die Website nutzen.</p>' +
    '<p class="h5">Google Analytics</p>' +
    '<p>Cookies von Google für Website-Analysen. Erzeugt anonymisierte, statistische Daten darüber, wie der Besucher die Website nutzt.</p>' +
    '<dl>' +
    '<dt>Anbieter</dt><dd>Google Ireland Limited</dd>' +
    '<dt>Cookie Name</dt><dd>_ga, _gat, _gid</dd>' +
    '<dt>Cookie Laufzeit</dt><dd>2 Jahre</dd>' +
    '<dt>Datenschutz</dt><dd><a href="https://policies.google.com/privacy?hl=de">policies.google.com/privacy</a></dd>' +
    '</dl>' +

    '<div class="cookie-consent-footer">' +
    '<ul><li class="first"><a href="https://www.christoph-baur.de/datenschutz">Datenschutz</a></li>' +
    '<li><a href="https://www.christoph-baur.de/impressum">Impressum</a></li></ul>' +
    '</div>' +
    '</div>' +
    '</div>';

  // Cookie consent modal and additional links.
  let cookieConsent = document.getElementById('cookie-consent')
  if (cookieConsent) {
    cookieConsent.innerHTML = cookieConsentContent;
  }
  let metaNav = document.getElementById('js-list-nav-meta')
  if (metaNav) {
    metaNav.insertAdjacentHTML('beforeend', '<li><a href="#" id="js-open-cookie-consent">Cookie-Einstellungen</a></li>')
  }

  // Open cookie consent modal box.
  let openCookieConsent = document.getElementById('js-open-cookie-consent')
  if (openCookieConsent) {
    openCookieConsent.addEventListener('click', function (e) {
      e.preventDefault()

      showCookieConsent()
    }, false)
  }

  // Open cookie consent modal box.
  let updateCookieConsent = document.getElementById('js-update-cookie-consent')
  if (updateCookieConsent) {
    updateCookieConsent.addEventListener('click', function (e) {
      e.preventDefault()

      showCookieConsent()
    }, false)
  }

  // Toggle cookie consent preferences.
  let preferencesBtn = document.getElementById('js-cookie-preferences')
  if (preferencesBtn) {
    preferencesBtn.addEventListener('click', function () {
      document.getElementById('js-cookie-preferences').classList.toggle('toggle-preferences--open')
      document.getElementById('js-modal-step-2').classList.toggle('cookie-consent-step2--hidden')
    }, false)
  }

  // Accept.
  let acceptBtn = document.getElementById('js-cookie-accept')
  if (acceptBtn) {
    acceptBtn.addEventListener('click', function () {
      setCookie('accept')
      loadAnalytics()
      hideCookieConsent()
    }, false)
  }

  // Optout.
  let optoutBtn = document.getElementById('js-cookie-optout')
  if (acceptBtn) {
    optoutBtn.addEventListener('click', function () {
      setCookie('optout')
      hideCookieConsent()
    }, false)
  }

  // Set cookie.
  function setCookie ($status) {
    let cookieTime = new Date(),
      cookieLater = new Date(),
      cookieValue = false

    if ('accept' === $status) {
      cookieValue = true
      document.getElementById('js-tracking-enabled').checked = true
    }

    if ('optout' === $status) {
      cookieValue = false
      document.getElementById('js-tracking-enabled').checked = false
    }

    cookieLater.setTime(parseInt(cookieTime.getTime()) + parseInt(2592000) * 1000) // One month.
    document.cookie = 'cb_settings=' + cookieValue + ';expires=' + cookieLater.toUTCString() + ';path=/;secure'
  }

  // Load Google Analytics.
  function loadAnalytics () {
    loadScript('https://www.google-analytics.com/analytics.js', function () {
      window.ga = window.ga || function () {(ga.q = ga.q || []).push(arguments)}
      ga.l = +new Date
      ga('create', 'UA-25923907-1', 'auto')
      ga('set', 'anonymizeIp', true)
      ga('send', 'pageview')
    })
  }

  // Hide cookie consent modal box.
  function hideCookieConsent () {
    cookieConsent.classList.remove('cookie-consent--open')
    cookieConsent.classList.add('cookie-consent--hidden')
  }

  // Show cookie consent modal box.
  function showCookieConsent () {
    cookieConsent.classList.remove('cookie-consent--hidden')
    cookieConsent.classList.add('cookie-consent--open')
  }

  // Handle cookie consent modal box on load.
  if (-1 === document.cookie.indexOf('cb_settings')) {
    if (!document.body.classList.contains('js-hide-cookie-consent')) {
      showCookieConsent()
    }
  } else {
    if ('true' === getCookieValue('cb_settings')) {
      document.getElementById('js-tracking-enabled').checked = true
      loadAnalytics()
    }
    hideCookieConsent()
  }

  // Load script helper.
  function loadScript (src, callback) {
    let s, r, t = false
    s = document.createElement('script')
    s.type = 'text/javascript'
    s.src = src
    s.onload = s.onreadystatechange = function () {
      if (!r && (!this.readyState || this.readyState == 'complete')) {
        r = true
        callback()
      }
    }
    t = document.getElementsByTagName('script')[0]
    t.parentNode.insertBefore(s, t)
  }

  // Get cookie value helper.
  function getCookieValue (a) {
    let b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)')
    return b ? b.pop() : ''
  }
})()
