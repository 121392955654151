import inView from 'in-view';

import './consent';
import './equalize';
import './retina';

// Add CSS class
function addClass(el, className) {
    if (el.classList) {
        el.classList.add(className);
    } else {
        el.className += ' ' + className;
    }
}

// Toggle CSS class
function toggleClass(el, className) {
    if (el.classList) {
        el.classList.toggle(className);
    } else {
        var classes = el.className.split(' ');
        var existingIndex = classes.indexOf(className);

        if (existingIndex >= 0)
            classes.splice(existingIndex, 1);
        else
            classes.push(className);

        el.className = classes.join(' ');
    }
}

// Nav interaction
if ( null !== document.getElementById('nav-opener') ) {
    var opener = document.getElementById('nav-opener');
    opener.addEventListener('click', function() {
        toggleClass(document.getElementById('nav-opener'), 'is-open');
        toggleClass(document.getElementById('logo'), 'is-hidden');
        toggleClass(document.getElementById('new-headline'), 'is-hidden');
        toggleClass(document.getElementById('subline'), 'is-hidden');
        toggleClass(document.getElementById('nav'), 'is-open');
        toggleClass(document.getElementById('nav-list'), 'is-active');
    });
}

// Section in view
inView('.section .col, .inview')
    .on('enter', function (el) {
        toggleClass(el, 'js-inview');
    })
    .on('exit', function (el) {
        toggleClass(el, 'js-inview');
    });

inView.offset(75);

inView('.list-skills li')
    .on('enter', function (el) {
        addClass(el, 'js-fadein');
    });

inView.offset(75);

inView('.section-kontakt')
    .on('enter', function (el) {
        toggleClass(document.getElementById('footer'), 'js-inview');
    })
    .on('exit', function (el) {
        toggleClass(document.getElementById('footer'), 'js-inview');
    });

inView.offset(75);

function addEvent(obj, evt, fn) {
  if (obj.addEventListener) {
    obj.addEventListener(evt, fn, false);
  } else if (obj.attachEvent) {
    obj.attachEvent("on" + evt, fn);
  }
}

addEvent(document, 'mouseout', function(evt) {
  if (evt.toElement == null && evt.relatedTarget == null) {
    // Checks if consent modal is still open.
    if (-1 !== document.cookie.indexOf('cb_settings')) {
      // Checks if exit intent modal has been shown.
      if (-1 === document.cookie.indexOf('cb_modal')) {
        Livewire.dispatch('openModal', {component: 'modals.request-seo-report'})
        let cookieTime = new Date(),
          cookieLater = new Date(),
          cookieValue = true;
        cookieLater.setTime(parseInt(cookieTime.getTime()) + parseInt(2592000) * 1000) // One month.
        document.cookie = 'cb_modal=' + cookieValue + ';expires=' + cookieLater.toUTCString() + ';path=/;secure'
      }
    }
  }
});
