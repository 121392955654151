// Equalize the height of all elements with a specific class name.
function equalHeights(className) {
    var el = document.getElementsByClassName(className),
        tallest = 0,
        max = el.length;

    // Step 1: Loop over matching el and reset height
    for (var i1 = 0; i1 < max; i1++) {
        el[i1].style.height = 'auto';
    }

    // Step 2: Loop over matching el and calculate tallest.
    for (var i2 = 0; i2 < max; i2++) {
        var ele = el[i2];
        var eleHeight = ele.offsetHeight;
        tallest = (eleHeight > tallest ? eleHeight : tallest);
    }
    // Step 3: Loop over matching el and set height.
    for (var i3 = 0; i3 < max; i3++) {
        el[i3].style.height = tallest + 'px';
    }

}

function setHeight(className, vh_desktop, vh_mobile) {
    var el = document.getElementsByClassName(className),
        max = el.length;

    for (var i = 0; i < max; i++) {
        // Set height according to window width.
        var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        var h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        if ( 667 >= w ) {
            if ( 'auto' !== vh_mobile ) {
                el[i].style.height = h * vh_mobile + 'px';
            } else {
                el[i].style.height = 'auto';
            }
        } else {
            el[i].style.height = h * vh_desktop + 'px';
        }
    }
}

// Add resize event instead of overwriting the default event.
var addEvent = function (object, type, callback) {
    if (object === null || typeof(object) === 'undefined') return;
    if (object.addEventListener) {
        object.addEventListener(type, callback, false);
    } else if (object.attachEvent) {
        object.attachEvent('on' + type, callback);
    } else {
        object['on' + type] = callback;
    }
};

// Set equal height on load.
equalHeights('js-about-equal-height');
equalHeights('js-lehrauftrag-equal-height');
// equalHeights('js-awards-equal-height');
setHeight('js-site-header', 0.75, 1);
setHeight('js-site-header-inner', 0.5, 'auto');
setHeight('js-section-work-col', 0.75, 1);
setHeight('js-section-lehrauftrag-col', 0.5, 1);

// Update equal height on resize.
addEvent(window, 'resize', function () {
    equalHeights('js-about-equal-height');
    equalHeights('js-lehrauftrag-equal-height');
    // equalHeights('js-awards-equal-height');
    setHeight('js-site-header', 0.75, 1);
    setHeight('js-site-header-inner', 0.5, 'auto');
    setHeight('js-section-work-col', 0.75, 1);
    setHeight('js-section-lehrauftrag-col', 0.5, 1);
});